import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { InView } from 'react-intersection-observer';
import NoSSR from '../Nav/NoSSR'

class LazyTwitter extends React.Component {
  state = {
    loaded: false
  }

  render() {
    const { identity, height } = this.props;

    return (
      <NoSSR>
        <InView onChange={(inView) => { if (inView) { this.setState({ loaded: true }) }}} fallbackInView={true}>
          {({ inView, ref }) => (
            <div ref={ref}>
              {(inView || this.state.loaded) && <TwitterTimelineEmbed sourceType="profile" screenName={identity} options={{height}} />}
            </div>
          )}
        </InView>
      </NoSSR>
    )
  }
}

LazyTwitter.defaultProps = {
  identity: 'Our_DA',
  height: 700
}

export default LazyTwitter